@import './styles/fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'NewEdge666', system-ui, sans-serif;
    background-color: #4E8926;
    height: 100%;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #4E8926;
    height: 100%;
  }

  @media (min-width: 768px) {
    html, body {
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }

  .font-newedge-rounded {
    font-family: 'NewEdge666-Rounded', system-ui, sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
