@font-face {
  font-family: 'NewEdge666';
  src: url('/public/assets/fonts/NewEdge666-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NewEdge666-Rounded';
  src: url('/public/assets/fonts/NewEdge666-Rounded.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} 